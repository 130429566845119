import React from 'react'
import ArticleItem from './ArticleItem'

const Articles = () => {
    const articles = [
        {
            date: '6 Nov, 2024',
            heading: 'How AI is going to positively disrupt the education sector',
            author: 'Cameron Aume',
            imageFile: 'article2-image.jpg',
            alt: 'Cameron Aume Presenting On Stage',
            link: 'https://www.theeducatoronline.com/k12/news/how-ai-is-going-to-positively-disrupt-the-education-sector/285983'
        },
        {
            date: '23 Aug, 2024',
            heading: 'The changing role of AI in Australia\'s early education sector',
            author: 'Brett Henebery',
            imageFile: 'article1-image.jpg',
            alt: 'Portrait of Peter Kazacos',
            link: 'https://www.theeducatoronline.com/k12/news/the-changing-role-of-ai-in-australias-early-education-sector/285448'
        }
    ]

    return (
        <section className="container-small relative mb-[160px]" id="articles">
            <h1 className='h1-large text-center mb-10'>Articles</h1>
            <div className='flex flex-col gap-y-[30px]'>
                {articles.map((article, index) => (
                    <ArticleItem key={index} date={article.date} heading={article.heading} author={article.author} imageFile={article.imageFile} alt={article.alt} link={article.link} />
                ))}
            </div>
        </section>
    )
}

export default Articles
